.MidContainer {
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 5px;
}

.MidContainerOne {
    display: flex;
    flex-direction: row;
}

.MidContainerTwo {
    display: flex;
    flex-direction: row;
}

.MidSubContainerOne {
    flex: 2;
   padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap:15px;
}

.MidSubContainerTwo {
    /* flex: 1; */
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding-right: 50px;
}

.MidSubContainerOneList{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
}

legend{
    display: none !important;
}

.MidSubContainerOneLabel{
    font-weight: 600;
}

.UserMgtBottomContainer{
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding:5px;
    
    width: 100%;
    
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}