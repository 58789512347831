.map-Marker {
  background-color: rgb(210, 11, 11);
  border-radius: 5px;
  font-weight: 600;
  word-break: break-all;
  margin-top: 20px;
}
.gm-style .gm-style-iw-c{
  padding: 2px !important;
}
.poly-Marker{
  background-color: rgb(240, 240, 240, 0.5);
  margin-top: -30px;
  
  white-space:pre;
  border-radius: 5px;
  padding: 4px;
  word-break: break-all;
}
.cluster-label {
  color: white !important;
}

