.LogoLoadercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.svg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  animation: rotate 1s linear infinite;
}

.svgOne {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  animation: rotateOne 1s linear infinite;
}

.circleOne {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 8px;
  stroke: #B00C2E;
  transform: translate(5px, 5px);
  stroke-linecap: round;
  stroke-dasharray: 150;
  stroke-dashoffset: 440;
  animation: animateOne 8s linear infinite;
}

@keyframes animateOne {

  0%,
  100% {
    stroke-dashoffset: 440;
  }

  50% {
    stroke-dashoffset: 0;
  }

  50.1% {
    stroke-dashoffset: 880;
  }

  /* 55%{
      stroke-dashoffset:880;
    } */
  /* 60%{
      stroke-dashoffset:880;
    } */
}


@keyframes rotateOne {
  0% {
    transform: rotate(360deg);
  }

  /* 25% {
    transform: roatate(180deg);
  }

  50% {
    transform: rotate(360deg);
  } */

  100% {
    transform: rotate(0deg);
  }
}

/* circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 7px;
  stroke: #DC1D13;
  transform: translate(5px, 5px);
  stroke-linecap: round;
  stroke-dasharray: 150;
  stroke-dashoffset: 440;
  animation: animate 4s linear infinite;
} */

@keyframes animate {

  0%,
  100% {
    stroke-dashoffset: 440;
  }

  50% {
    stroke-dashoffset: 0;
  }

  50.1% {
    stroke-dashoffset: 880;
  }

  /* 55%{
          stroke-dashoffset:880;
        } */
  /* 60%{
          stroke-dashoffset:880;
        } */
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.i {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  margin-bottom: 0px;
}

.logoContainer {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.Logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
}


.logoText {
  position: absolute;
  width: 40px;
  height: 40px;

  overflow: hidden;
}

.logoTitle {
  width: 100%;
  height: 100%;
  object-fit:contain;
    border-radius: 100%;
}