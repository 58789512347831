input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.label-text.MuiInputLabel-root {
  color: black !important;
}
.MenuItemSelect.MuiMenuItem-root {
  color: black !important;
}

.input-text input {
  color: black;
}

.TextBlack.MuiOutlinedInput-input {
  color: black !important;
}

.textfield {
  background-color: #000;
  color: green;
}
