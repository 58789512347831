.NumberTextContainerOtp{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;  
}


.TextFieldOtp{
    display: flex;
    width:40px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    border: 2px solid #7d203f;
    outline: none; 
    text-align: center;
    
}

.ButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px 0px;
}

.AuthContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}