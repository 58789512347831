.UserMgtBottomContainer{
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding:5px;
    padding-bottom: 25px;
    width: 100%;
    gap:15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}