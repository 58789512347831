.SalesContainer{
    width:100%;
    display: flex;
    flex-direction: column;
    gap:10px;
    
}

.Row{
    display: flex;
    flex-direction: row;
}

.TopContainer{
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        border-radius: 5px;
    padding:0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.ZoneContainer{
    display: flex;
    flex-direction: row;
    gap:10px;
    padding: 10px;
}

.DateContainer{
    display: flex;
    flex-direction: row;
    gap:10px;
    padding: 10px;
}

.DateList{
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap:10px;
}

.DateText{
    font-size: 14px;
}