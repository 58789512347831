.MuiTableRow-head{
    border-bottom: 2px solid black !important;
}
.css-15wwp11-MuiTableHead-root{
    border-bottom: 2px solid black !important;
}
.MuiTableHead-root{
    border-bottom: 2px solid black !important;
}


.MuiTableRow-root{
    border-bottom: 2px solid black !important;
}

.head{
    background-color: #c4c4c4 !important;
}