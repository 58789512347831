.ResetButton{
    position: absolute;
    z-index: 999;
    background-color: white;
    border: none;
    outline: none;
    padding: 8px 22px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 2px;
    transform: translate(10px,55px);
 
}

.ResetButton:hover{
    background-color: #e6e6e6;
}

.BackButton{
    position: absolute;
    z-index: 999;
    background-color: white;
    border: none;
    outline: none;
    padding: 8px 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 2px;
    transform: translate(10px,55px);
  
}
.BackButton:hover{
    background-color: #e6e6e6;
}

.ZoneContainer{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: absolute;
    z-index: 999;
    background-color: white;
    padding: 8px 15px;
    transform: translate(1520px,55px);
  
}