
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Global scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar for horizontal scrolling */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #C4C4C4; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4; /* Color of the scrollbar thumb on hover */
}

::-webkit-scrollbar-corner {
  background: transparent; /* Color of the scrollbar corner */
}
.MuiList-root {
  display: flex !important;
  flex-direction: column !important;
}
.MuiMenuItem-root {
  padding: 4px !important;
}