.UserManagementContainer{
    /* -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75); */
    display: flex;
    /* width:80vw; */
    width: calc(100vw - 105px);
    flex-direction: column;
    gap:10px; 
    
}
@media screen and (max-width: 1023px) {
    .UserManagementContainer{
        display: flex;
        width: calc(100vw);
        flex-direction: column;
        gap:10px; 
    }
}
.UserManagementCarousel{
    margin-bottom: 5px;
    margin-left: 4px;
    background-color: white;
    display: flex;
    flex-direction: row;
    min-height:50px;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
    margin-right: 4px;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    gap:10px;
    border-radius: 5px;
    z-index: 999;
}
.UserManagementCarousel_sales{
    margin-bottom: 5px;
    margin-left: 4px;
    background-color: white;
    display: flex;
    flex-direction: row;
    min-height:30px;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
    margin-right: 4px;
    gap:10px;
    border-radius: 5px;
    z-index: 999;
}

.UserManagementCarouselLabelWrapperOne{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    width:50%;
    border-bottom: 3px solid #DC1D13;
    cursor: pointer;
    
}
.UserManagementCarouselLabelWrapperTwo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    width:50%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-bottom: 3px solid #757373;
 
}

.UserManagementCarouselLabelOne{
    color:#DC1D13;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 14px;
  }



.UserManagementCarouselLabelTwo{
    color:black;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    transition: 0.3s ease-in-out;
}