.MuiTableRow-head {
    border-bottom: 2px solid black !important;
}

.css-15wwp11-MuiTableHead-root {
    border-bottom: 2px solid black !important;
}

.MuiTableHead-root {
    border-bottom: 2px solid black !important;
}


.MuiTableRow-root {
    border-bottom: 2px solid black !important;
}

.head {
    background-color: #c4c4c4 !important;
}

.ListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.List {
    width: 100%;
}

.ListUl {
    width: 100%;
    list-style: circle;
}

.ListLi {
    width: 100%;
    display: flex;
    flex-direction: row;

}

.ProductTitle{
    /* flex:2; */
 
    min-width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ProductSlPrice{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:0.3;
}

.ProductPrice{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:1;
}

.ProductQuantity{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:1;
}

.ProductTotal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  flex:1;
}

.TotalContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.NoDataContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}



.ProductHeadTitle{
    /* flex:2; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    min-width: 350px;
}

.ProductHeadPrice{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:1;
}

.ProductSlHeadPrice{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:0.3;
}

.ProductHeadQuantity{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:1;
}

.ProductHeadTotal{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  flex:1;
}

.TotalContainerAllCost{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:15px;
}

.TotalContainerCost{
    min-width: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}

.TotalContainerCostTitle{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Symbol{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 0px;
    margin-right: 2px;
}

.rowTablecell{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}