.SalesMidContainer{
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        border-radius: 5px;
        background-color: white;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap:10px;
        /* width: 100%; */
}

.SalesRouteContainer{
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    justify-content: space-between;
    gap:25px;
    padding: 10px;
}

.SalesRouteList{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.Row{
    display: flex;
    flex-direction: row;
    gap: 10px;
}