.Container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0px;
}

.TitleContainer{
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
}

.Title{
    font-weight: 600;
    font-size: 16px;
}

.SubContainer{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;

}

.SubContainerOne{
    flex:1;
    width:100%;
    display: flex;
    flex-direction: column;
    gap:10px;

}

.SubContainerTwo{
    flex:1;
    width:100%;
    display: flex;
    flex-direction: column;
    gap:10px;


}

.SubContainerList{
    display: flex;
    flex-direction: row;
    gap:10px;
    
    
}

.LabelContainer{
    flex:1;
   display: flex;
   justify-content: flex-end;
}

.Label{
    font-weight: 600;
}

.FieldContainer{
    flex:2;
    display:flex;
    flex-direction: column;
}

.ButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:15px 10px;
    margin-top: 10px;
    gap:10px;
}

.EditContainer{
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    padding:10px;
    border-radius: 100%;
    cursor: pointer;
}

.error{
    color:red;
    font-size: 12px;
}