.map-Marker {
  margin-top: -30px;
  background-color: white;
  border-radius: 5px;
  font-weight: 600;
  word-break: break-all;
  
}
.info-container{
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.db-name-wrapper{
  display: flex;
  gap: 2px;
}
.retailer-detail{
  display: flex;
  flex-direction: row;
}
.marker-label img {
  width: 12px !important;
  height: 12px !important;
}

.marker-label{
  font-size: 10px !important;
}
.gm-style .gm-style-iw-c {
  background-color: transparent;
  box-shadow: none;
}

.gm-style .gm-style-iw-tc::after {
  display: none !important;
}

/* for close button  */
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-d {
  overflow: visible !important;
}

.cluster-label {
  color: white !important;
}

.MuiTabs-root {

  min-height: 30px !important;

}
.lp-name{
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  border-radius: 10px;
  font-size: 10px !important;
  padding: 2px 4px ;
  width: fit-content;
  display: flex;
  gap: 2px;
}
.db-name{
  background-color: black;
  color: #fff;
  border-radius: 10px;
  font-size: 10px !important;
  padding: 2px 4px;
}
.db-name-delivery-mgt{
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  border-radius: 10px;
  font-size: 10px !important;
  padding: 2px 4px;
}