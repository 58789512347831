.UserMgtWholesalerUpdateFormScrollViewContainer{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
}


.UserMgtWholesalerTable{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
}

.MidContainer {
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 5px;
}

.MidContainerOne {
    display: flex;
    flex-direction: row;
}

.MidContainerTwo {
    display: flex;
    flex-direction: row;
}

.MidSubContainerOne {
    flex: 2;
   padding: 10px 30px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap:15px;
}

.MidSubContainerTwo {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding-right: 50px;
}

.MidSubContainerOneList{
    display: flex;
    flex-direction: row;
    gap:10px;
}

legend{
    display: none !important;
}

.MidSubContainerOneLabel{
    font-weight: 600;
}

.UserMgtBottomContainer{
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding:5px;
    padding-bottom: 25px;
    width: 100%;
    gap:15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}


.Title{
    background-color: #DC1D13;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    color: white;
    z-index: 999;
    border-radius: 5px;
    font-weight: 600;
}

.label{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}