.Container {
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 100%;
  text-align: center;
}

/* MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,.MuiInputBase-colorPrimary{
    border-color: #F2843F !important;
} */

.OneContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OneWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.SaathiLogoContainer {
  display: flex;
  width: 300px;
  padding: 30px 50px;
}

.SaathiLogo {
  width: 100%;
  height: 100%;
}

.NumberTextContainerOtp {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.NumberTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.TextContainerOtp {
  display: flex;
  flex-direction: row;
  width: 40px;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
  padding: 7.3px;
  border-radius: 5px;
  border: 2px solid #f2843f;
  flex-wrap: wrap;
}

.TextFieldOtp {
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: 2px solid #f2843f !important;
  outline: none;
  text-align: center;
}

.TextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
  padding: 7px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid #f2843f;
}

.TextField {
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: inherit;
  color: black;
  /* border: 2px solid #F2843F; */
}

.NumberStart {
  border: 2px solid #f2843f;
  color: #f2843f;
  font-weight: 600;
  border-right: 0;
  padding: 8.2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.UpstoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.Upstox {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UpstoxImage {
  width: 100%;
  height: 100%;
}

.TwoContainer {
  flex: 1;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* background: #F2843F12; */
.section {
  background-color: #f2843f12;
  /* background-color: #c9255c42; */

  min-height: 80vh;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 5px;
  /* gap:15px; */
}

.listOne {
  display: flex;
  padding: 0px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 10px;
}

.LoginTitle {
  color: #716f6f;
  padding: 0px 5px;
  font-size: 24px;
}

.LoginTitleDark {
  color: #f2843f !important;
  margin-left: 10px;
}

.NumberTitle {
  font-size: 12px;
  color: #716f6f !important;
  font-weight: 500;
  /* padding: 5px 10px; */
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.errorMessage {
  font-size: 12px;
  color: red !important;
  font-weight: 500;
  /* padding: 5px 10px; */
  width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.term {
  color: #716f6f;
  font-size: 14px;
}

.termTitle {
  color: #f2843f !important;
  border-bottom: 2px solid #f2843f !important;
}

.ErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 30vw;
}

.ErrorImgContainer {
  width: 100px;
}

.ErrorIcon {
  width: 100%;
  height: 100%;
}

.ErrorLabel {
  padding: 10px;
  color: #716f6f;
  font-size: 24px;
  font-weight: 600;
}

.SuccessContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 30vw;
}

.SuccessImgContainer {
  width: 100px;
}

.SuccessIcon {
  width: 100%;
  height: 100%;
}

.SuccessLabel {
  padding: 10px;
  color: #716f6f;
  font-size: 24px;
  font-weight: 600;
}

.SuccessSubLabel {
  color: #f2843f !important;
}

.error {
  font-size: 12px;
  color: red;
  padding: 10px;
}

.againTitle {
  color: rgb(24, 153, 167);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 2px solid blue;
}

.againTitleContainer {
  cursor: pointer;
  /* background-color: #F2843F; */
  padding: 5px 10px;
  border-radius: 5px;
}
