.UserMgtWholesalerUpdateFormContainer{
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
}

.UserMgtWholesalerUpdateFormContainerOne{
    flex:2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    color: black !important;
    opacity: 1 !important;
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
}

.MuiInputBase-input{
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
    color: black !important;
    opacity: 1 !important;
} 

.Mui-disabled{
    color: black !important;
    opacity: 1 !important;
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
}

/* }  css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{ */

.MuiOutlinedInput-input {
    color: black !important;
    opacity: 1 !important; 
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;

}
.MuiInputBase-inputSizeSmall{
    color: black !important;
    -webkit-text-fill-color: black !important;
    opacity: 1 !important;  
    -webkit-tap-highlight-color: black !important;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    color: black !important;
    opacity: 1 !important; 
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
}

.UserMgtWholesalerUpdateFormContainerSectionOne{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding: 10px 20px;
}

.UserMgtWholesalerUpdateFormContainerSectionOneM{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
}

.UserMgtWholesalerUpdateFormSectionOneTitleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.UserMgtWholesalerUpdateFormSectionOneTitle{
    font-weight: 600;
    font-size: 26px;
    color:#DC1D13;
}

.UserMgtWholesalerUpdateFormSectionOneEditContainer{
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    border-radius: 100%;
    padding: 10px;
}

.UserMgtWholesalerUpdateFormSectionOneEditIcon{
    width: 25px;
    height:25px
}

.UserMgtWholesalerUpdateFormContainerSectionTwo{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
}
.UserMgtWholesalerUpdateFormTwoSubSectionOne{
    margin-top: 20px;
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    gap:15px;
}

.UserMgtWholesalerUpdateFormTwoSubSectionOneList{
    display: flex;
    flex-direction: row;
    gap:10px;
}
.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne{
    flex:1;
    display: flex;
    justify-content: flex-end;
}
.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo{
    flex:3;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel{
    font-weight: 600;
    font-size: 14px;
}
.UserMgtWholesalerUpdateFormTwoSubSectionOneListField{
    width:100%;
    color:black !important;
   
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 1em !important;
}

.UserMgtWholesalerUpdateFormTwoSubSectionTwo{
    margin-top: 20px;
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    gap:15px;
}

.UserMgtWholesalerUpdateFormContainerSectionThree{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:15px;
    justify-content: center;
    align-items: center;
}

.UserMgtWholesalerUpdateFormContainerTwo{
    flex:0.8;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:2px;
}

.UserMgtWholesalerUpdateFormContainerTwoProfileContainer{
   width: 120px;
   height:120px;
   background-color: white;
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.UserMgtWholesalerUpdateFormContainerTwoProfile{
    width: 105px;
    height:105px;
    object-fit: cover;
    border-radius: 100%;
}

.UserMgtWholesalerUpdateFormContainerTwoFirmContainer{
  width: 100%;
  height: 160px;
}

img{
    width: 100%;
    height:100%;
    object-fit: cover;
}

.UserMgtWholesalerUpdateFormContainerTwoListContainer{
    display: flex;
    width:100%;
    flex-direction: column;
}

.UserMgtWholesalerUpdateFormContainerTwoList{
    display: flex;
    width:100%;
    flex-direction: row; 
    margin-top: 10px;
}

.UserMgtWholesalerUpdateFormContainerTwoListOne{
    flex:1;
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    justify-content: flex-start;
    
}

.UserMgtWholesalerUpdateFormContainerTwoListTwo{
    flex:1.7;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.UserMgtWholesalerUpdateFormContainerTwoListOneIconContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #DC1D13;
    margin-left: 18px;
    color:white;
    padding:5px;
    border-radius: 100%;
}

.UserMgtWholesalerUpdateFormContainerTwoListOneIcon{
    width: 20px;
    height:20px;
}

.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel{
    color:red;
    font-size: 12px;
}


.UserMgtUpdateFormContainerTwoList{
    display: flex;
    width:100%;
    flex-direction: row; 
    margin-top: 10px;
}

.UserMgtUpdateFormContainerTwoListOne{
    flex:1;
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    justify-content: flex-start;
    
}

.UserMgtUpdateFormContainerTwoListTwo{
    flex:1.7;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.UserMgtUpdateFormContainerTwoListOneIconContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #DC1D13;
    margin-left: 18px;
    color:white;
    padding:5px;
    border-radius: 100%;
}

.UserMgtUpdateFormContainerTwoListOneIcon{
    width: 20px;
    height:20px;
}

.UserMgtUpdateFormContainerSectionOneM{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
}

.UserMgtUpdateFormSectionOneTitleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.UserMgtUpdateFormSectionOneTitle{
    font-weight: 600;
    font-size: 26px;
    color:#DC1D13;
}