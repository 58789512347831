.Container {

    border-radius: 5px;
    padding: 10px;

    display: flex;
    flex-direction: column;
}

.FirstContainer {
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.TableContainer {
    flex: 1;
    margin-top: 5px;

}

.DetailContainer {
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
}

.ZoneContainer {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.DetailSubContainer{
    background-color: #f2f2f2;
}